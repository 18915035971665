import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from "react-router-dom";
import { prepareGTMDataset } from '../../helpers/googleTagManager';
import './footer.scss';

import arrowIcon from '../../assets/img/next-icon-gradient.svg';
import promoJoinImg from '../../assets/img/promo/join-club.png';

const footerMenu = [
    {link: '/contact', title: "Contact Us", gtm: 'data-gtm-contact-us-link-a'},
    {link: '/about', title: "About Ken's", gtm: 'data-gtm-about-link-a'},
    {link: '/faq', title: "Faqs", gtm: 'data-gtm-faqs-link-a'}
];

class Footer extends Component {

    constructor(props) {
      super(props);

      this.state = {
          emailValue: ''
      };
    }

    getFooterNav() {
        let index = 0;

        return (
            footerMenu.map((item) => {
                return (
                    <NavLink key={index++} {...prepareGTMDataset(item.gtm)} to={item.link}>{item.title}</NavLink>
                )
            })
        );
    };

    render() {
        const { location: { pathname }} = this.props;
        const showPromo = !pathname.match('collections');

        return (
            <footer className="no-print">
                <div className="promo-wrapper">
                    {showPromo &&
                      <div className="promo-recipes">
                          <h2>Ready for More Recipes?
                          </h2>
                          <NavLink to="/collections">
                                Try One From Our Favorite Recipe <span>Collections <img className="arrow-link" src={arrowIcon} alt="see more" /></span>
                            </NavLink>
                      </div>
                    }
                    <div className="promo-join">
                        <img src={promoJoinImg} alt="" />
                        <h4>Join the club</h4>
                        <span>Participate in giveaways, share ideas, receive exclusive news and member only offers. <a href="https://bit.ly/3qWYuQs" target="_blank" rel="noopener noreferrer">Click Here to Join</a>
                        </span>
                    </div>
                    <div id="mc_embed_signup" className="sign-form">
                        <form action="https://facebook.us3.list-manage.com/subscribe/post?u=d061f0afc199b8d0d213c2810&amp;id=2917b137c0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" data-gtm-sign-up-link-a className="validate" target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                        	      <h4>Sign up for delicious news &amp; recipes</h4>
                                <div className="form-group-inline">
                                	  <input
                                        className="required email"
                                        type="email"
                                        name="EMAIL"
                                        required
                                        placeholder="yourname@domain.com"
                                        onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
                                        value={this.state.emailValue}
                                    />
                                    <div id="mce-responses" className="clear">
                                  		  <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                                  		  <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                                  	</div>
                                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true'>
                                        <input type="text" name="b_d061f0afc199b8d0d213c2810_2917b137c0" tabIndex="-1" value="" readOnly />
                                    </div>
                                    <button type="submit" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="footer-wrapper">
                    <div>
                        <nav className="social-nav">
                            <a href="https://www.facebook.com/KensSteakHouseDressings" target="_blank" rel="noopener noreferrer"><img  data-gtm-facebook-link-a src="/images/icons/facebook-icon.svg" alt="Facebook" /></a>
                            <a href="https://instagram.com/kensdressings" target="_blank" rel="noopener noreferrer"><img data-gtm-instagram-link-a src="/images/icons/instagram-icon.svg" alt="Instagram" /></a>
                            <a href="http://www.pinterest.com/kensdressings" target="_blank" rel="noopener noreferrer"><img data-gtm-pinterest-link-a src="/images/icons/pinterest-icon.svg" alt="Pinterest" /></a>
                            <a href="https://www.youtube.com/user/KensDressing" target="_blank" rel="noopener noreferrer"><img data-gtm-youtube-link-a src="/images/icons/youtube-icon.svg" alt="Youtube" /></a>
                        </nav>
                        <ul className="legal">
                            <li>&copy; {(new Date().getFullYear())} Ken's Foods, LLC</li>
                            <li>
                                <a href="/privacy" data-gtm-privacy-link-a>Privacy Policy</a>
                                <a href="/legal" data-gtm-legal-link-a>Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                    <nav className="footer-nav">
                        {this.getFooterNav()}
                        <a href="https://jobs.kensfoods.com/" target="_blank" rel="noopener noreferrer" title="Careers at Ken's." data-gtm-careers-link-a>Careers</a>
                        <a href="https://www.kensfoodservice.com/" target="_blank" rel="noopener noreferrer" title="Foodservice" data-gtm-careers-link-a>Foodservice</a>
                    </nav>
                </div>
            </footer>
        );
    }
};

const mapStateToProps = (state) => ({
    location: state.router.location
});

export default withRouter(connect(mapStateToProps)(Footer));
